import { t } from '@/i18next';
import {
  deleteOrganizationLogo,
  disableOrganizationTwoFactor,
  forceOrganizationTwoFactor
} from '@/api/organization';

import {
  DELETE_ENTITY_MODAL,
  PASSWORD_CONFIRMATION_REQUIRED_MODAL
} from '@/constants/modals';

import { ORGANIZATION_API } from '@/constants/api';

import type { OrganizationSimplified } from '@/types/organizations';
import type { PasswordConfirmationParams } from '@/api/profile/types';
import type { PromiseResolve } from '@/types/helpers';
import type { UseModal } from '@/types/modals';

export function openDeleteLogoModal(
  openModal: UseModal['openModal'],
  organization: OrganizationSimplified
) {
  async function onDeleteLogoHandler(resolve: PromiseResolve) {
    const data = await deleteOrganizationLogo(organization.id);
    resolve(data);
  }

  openModal<typeof DELETE_ENTITY_MODAL>(DELETE_ENTITY_MODAL, {
    confirmAction: onDeleteLogoHandler,
    useStatusConfirmation: true,
    invalidateQueryKey: ORGANIZATION_API,
    name: organization.name,
    confirmTranslation: 'common.delete',
    modalMessage: 'organization.delete_logo',
    successMessage: 'common.deletedSuccessfully',
    successMessageParams: {
      entityType: t('common.logo_placeholder'),
      entityName: organization.name
    }
  });
}

export function openForceOrganizationTwoFactorConfirmationModal(
  openModal: UseModal['openModal'],
  organization: OrganizationSimplified
) {
  async function onForceOrganizationTwoFactorHandler(
    resolve: PromiseResolve,
    params: PasswordConfirmationParams
  ) {
    const data = await forceOrganizationTwoFactor(params, organization.id);

    resolve(data);
  }

  openModal<typeof PASSWORD_CONFIRMATION_REQUIRED_MODAL>(
    PASSWORD_CONFIRMATION_REQUIRED_MODAL,
    {
      confirmAction: onForceOrganizationTwoFactorHandler,
      useStatusConfirmation: true,
      successMessage: 'organization.two_factor_forced_successfully',
      title: 'organizationDashboard.force_two_factor_authentication'
    }
  );
}

export function disableOrganizationTwoFactorConfirmationModal(
  openModal: UseModal['openModal'],
  organization: OrganizationSimplified
) {
  async function onDisableOrganizationTwoFactorHandler(
    resolve: PromiseResolve,
    params: PasswordConfirmationParams
  ) {
    const data = await disableOrganizationTwoFactor(params, organization.id);

    resolve(data);
  }

  openModal<typeof PASSWORD_CONFIRMATION_REQUIRED_MODAL>(
    PASSWORD_CONFIRMATION_REQUIRED_MODAL,
    {
      confirmAction: onDisableOrganizationTwoFactorHandler,
      useStatusConfirmation: true,
      successMessage: 'organization.two_factor_disabled_successfully',
      title: 'organization.disable_two_factor'
    }
  );
}
